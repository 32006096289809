import { Grid, Line, Spinner } from "presentation/components"
import routesPaths from "presentation/routes/routesPaths"
import history from "@/services/history"
import { LoadingWrapper } from "../styles"
import GeneralData from "./generalData"
import Addresses from "./addresses"
import Contacts from "./contacts"
import ComercialData from "./comercialData"
import BindedCards from "./bindedCards"
import { Button } from "@stationkim/components-lib"
import useService from "main/hooks/useService"
import { putEditResaleGeneralData, putFinalizeResaleRegister, putPublishResale } from "@/services/resalesServices"
import { useDispatch } from "react-redux"
import { pushNotification } from "@/store/modules/notification/actions"
import successHandler from "main/utils/successHandler"
import { RESALES_STATUS } from "main/utils/constants"
import { useEffect, useState } from "react"
import { ModalConfirmPublish } from "../../modals/modalConfirmPublish"
import { ModalConfirmCreation } from "../../modals/modalConfirmCreation"
import { Plans } from "./plans"
import { Checkbox, CheckboxField } from "@stationkim/front-ui"
import { ConfirmationDialog } from "./confirmationDialog/confirmationDialog"

const CustomLine = () => (
  <Grid.Row>
    <Grid.Col>
      <Line color="#DEDCDC" />
    </Grid.Col>
  </Grid.Row>
)

export const ResaleData = ({ resaleData, isReviewing, goToRegisterStep, isLoading, refresh, onCancel }) => {
  const dispatch = useDispatch()

  const [isPublishConfirmOpen, setIsPublishConfirmOpen] = useState(false)
  const [isNotificationOpen, setIsNotificationOpen] = useState(false)

  const [isGeneratingInvoice, setIsGeneratingInvoice] = useState(resaleData?.allowDataNfe || false)
  const [isInvoiceConfirmationOpen, setIsInvoiceConfirmationOpen] = useState(false)
  const [finalizeRegisterState, finalizeRegisterRequest] = useService(putFinalizeResaleRegister, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler("Revenda cadastrada com sucesso!")))
      setIsNotificationOpen(true)
    },
  })

  const [publishResaleState, publishResaleRequest] = useService(putPublishResale, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler("Revenda publicada com sucesso!")))
      history.push("/revendas")
    },
  })

  const [editGeneralDataState, editGeneralDataRequest] = useService(putEditResaleGeneralData, {
    onCompleted: () => {
      dispatch(pushNotification(successHandler(`Nota fiscal ${isGeneratingInvoice ? 'desabilitada' : ' habilitada'}`)));
      setIsGeneratingInvoice(state => !state)
    },
  });

  const handleInvoiceOptionChange = () => {
    if (!isGeneratingInvoice || isInvoiceConfirmationOpen) {
      if (resaleData) editGeneralDataRequest({ ...resaleData, allowDataNfe: !resaleData.allowDataNfe })
    }
    else setIsInvoiceConfirmationOpen(true)
  }

  useEffect(() => {
    if (resaleData)
      setIsGeneratingInvoice(resaleData.allowDataNfe)
  }, [resaleData])

  return (
    <>
      {isLoading || !resaleData || finalizeRegisterState.loading || publishResaleState.loading || editGeneralDataState.loading ? (
        <LoadingWrapper>
          <Spinner />
        </LoadingWrapper>
      ) : (
        <>
          <Grid padding="0" spaceBetweenRows="32px">
            <GeneralData
              resaleData={resaleData}
              isReviewing={isReviewing}
              onReview={() => goToRegisterStep(0)}
              onEdited={refresh}
            />
            <CheckboxField
              label="Permite o envio de dados para geração de Nota Fiscal"
              name="allowDataNfe"
              onChange={handleInvoiceOptionChange}
              value={isGeneratingInvoice}
            />
            <ConfirmationDialog
              open={isInvoiceConfirmationOpen}
              color="error"
              title="Deseja desativar a geração de Nota Fiscal para esta revenda?"
              innerContent={
                <>
                  Isso pode impactar as integrações, envio de dados e processos financeiros.<br />
                  Tem certeza de que deseja continuar?
                </>
              }
              onClose={() => setIsInvoiceConfirmationOpen(false)}
              onConfirm={handleInvoiceOptionChange}
            />
            <CustomLine />
            <Plans plans={resaleData.availablePlans} />
            <CustomLine />
            <Contacts
              resaleData={resaleData}
              isReviewing={isReviewing}
              onReview={() => goToRegisterStep(1)}
              onEdited={refresh}
            />
            <CustomLine />
            <Addresses
              resaleData={resaleData}
              isReviewing={isReviewing}
              onReview={() => goToRegisterStep(2)}
              onEdited={refresh}
            />
            <CustomLine />
            <BindedCards
              resaleData={resaleData}
              isReviewing={isReviewing}
              onReview={() => goToRegisterStep(3)}
              onEdited={refresh}
            />
            <CustomLine />
            <ComercialData
              resaleData={resaleData}
              isReviewing={isReviewing}
              onReview={() => goToRegisterStep(4)}
              onEdited={refresh}
            />
            <Grid.Row justify="end">
              {isReviewing ? (
                <>
                  <Grid.Col xs={12} sm={6} md={3} lg={3}>
                    <Button buttonType="text" full onClick={onCancel}>
                      Cancelar
                    </Button>
                  </Grid.Col>
                  <Grid.Col xs={12} sm={6} md={3} lg={3}>
                    <Button full onClick={() => finalizeRegisterRequest(resaleData.id)}>
                      CONCLUIR
                    </Button>
                  </Grid.Col>
                </>
              ) : (
                <>
                  <Grid.Col xs={12} sm={6} md={3} lg={3}>
                    <Button
                      full
                      buttonType={resaleData?.status === RESALES_STATUS.WAITING_PUBLISH ? "outlined" : undefined}
                      onClick={() => history.push(routesPaths.resales.base)}
                    >
                      Voltar
                    </Button>
                  </Grid.Col>
                  {resaleData?.status === RESALES_STATUS.WAITING_PUBLISH ? (
                    <Grid.Col xs={12} sm={6} md={4} lg={3}>
                      <Button full onClick={() => setIsPublishConfirmOpen(true)}>
                        Publicar Revenda
                      </Button>
                    </Grid.Col>
                  ) : null}
                </>
              )}
            </Grid.Row>
          </Grid>
          <ModalConfirmCreation open={isNotificationOpen} />
          <ModalConfirmPublish
            open={isPublishConfirmOpen}
            onClose={() => setIsPublishConfirmOpen(false)}
            onConfirm={() => publishResaleRequest(resaleData?.id)}
          />
        </>
      )}
    </>
  )
}
