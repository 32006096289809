import { faQuestionCircle, faXmarkCircle } from "@fortawesome/free-regular-svg-icons"
import { faExclamationTriangle, IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { Theme, useTheme } from "@mui/material"
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Icon, Typography } from "@stationkim/front-ui"

type TColor = 'primary' | 'error' | 'warning'
type TIcon = 'exclamation' | 'question' | 'error'
interface ConfirmationDialogProps extends DialogProps {
  title: string,
  onConfirm: () => void,
  onClose: () => void,
  onCancel: () => void,
  innerContent?: React.ReactNode,
  color?: TColor
  icon?: TIcon | IconDefinition
  cancelButtonLabel?: string,
  confirmButtonLabel?: string,
  closeAndCancelIsTheSame?: boolean
  hideIcon?: boolean
  triggerCloseOnConfirm?: boolean
}

const icons: Record<TIcon, IconDefinition> = {
  error: faXmarkCircle,
  exclamation: faExclamationTriangle,
  question: faQuestionCircle
}

export const ConfirmationDialog = ({
  open,
  title,
  children,
  innerContent,
  onClose,
  onCancel,
  onConfirm,
  cancelButtonLabel = 'Cancelar',
  confirmButtonLabel = 'Confirmar',
  color = 'primary',
  icon = 'exclamation',
  hideIcon = false,
  closeAndCancelIsTheSame = true,
  triggerCloseOnConfirm = true
}: ConfirmationDialogProps) => {
  const theme = useTheme()

  const colors: Record<TColor, string> = {
    primary: theme.palette.primary.main,
    error: theme.palette.error.main,
    warning: theme.palette.warning.main,
  }

  const colorInUse = colors[color]
  const iconInUse = typeof icon === 'string' ? icons[icon] : icon

  const innerOnCancel = closeAndCancelIsTheSame ? onClose : onCancel
  const innerOnConfirm = () => {
    onConfirm()
    triggerCloseOnConfirm && onClose()
  }
  return (
    <Dialog open={open} onClose={onClose}>
      {!hideIcon &&
        <Icon icon={iconInUse} sx={(theme: Theme) => ({
          height: '58px',
          width: '58px',
          margin: '0 auto 16px auto',
          padding: '16px',
          borderRadius: '50%',
          backgroundColor: theme.palette.grey[50],
          color: colorInUse
        })} />
      }
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ margin: '24px 0' }}>
        {innerContent ?
          <Typography sx={{ color: theme.palette.grey[600] }}>{innerContent}</Typography> :
          children
        }
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', gap: '16px' }}>
        <Button variant='outlined' onClick={innerOnCancel} fullWidth sx={{ filter: 'grayscale(100)' }}>{cancelButtonLabel}</Button>
        <Button variant='contained' onClick={innerOnConfirm} fullWidth color={color}>{confirmButtonLabel}</Button>
      </DialogActions>
    </Dialog>
  )
}